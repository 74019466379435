import React from 'react';
import styled from 'styled-components';
import Template from '@beelineloans/cx-library/src/components/layout/TemplateV2';
import SEO from '@beelineloans/cx-library/src/components/SEO';
import CONST from '@beelineloans/cx-library/src/utils/constants';
import { NumberedListWithImage, VideoWithHeading, TextWithChecklistImg, PlantAndCoffee, FishAndText, LoanGuideConvo } from '@beelineloans/cx-library/src/components/layout/panels';
import { Breakpoint, Paragraph, Legal } from '@beelineloans/cx-library/src/components/typography';
import SiteCopy from '@beelineloans/cx-library/src/utils/site-copy';

const LegalWrap = styled.div`
  position: relative;
  z-index: 100;
  text-align: center;
  width: 90%;
  margin: 0 auto;
  top: 350px;
  p {
    color: ${(props) => props.theme.colours.text.light};
  }

  @media only screen and (${(props) => props.theme.mediaBreakpoints.tablet}) {
    width: 60%;
    top: 650px;
    p {
      color: ${(props) => props.theme.colours.text.dark};
    }
  }
`;

const RefinanceLowerRepayments = () => {
  const headlineBlock = <Breakpoint smallBreakpoint={<>More money in your pocket each month.</>} largeBreakpoint={<>More money in your pocket each month.</>} />;

  const subheadlineBlock = (
    <>
      Skip the BS application.
      <br />
      Shrink your monthly payments* in 15 mins.
      <br />
      It’s a no brainer.
    </>
  );

  const numberedList = [
    {
      number: 1,
      noBorder: false,
      ...SiteCopy.numberedList.LowRatesRefi
    },
    {
      number: 2,
      noBorder: false,
      ...SiteCopy.numberedList.Radical
    },
    {
      number: 3,
      noBorder: false,
      ...SiteCopy.numberedList.WhereYouStand
    },
    {
      number: 4,
      noBorder: true,
      ...SiteCopy.numberedList.LoanGuideRefi
    }
  ];

  return (
    <Template
      headline={headlineBlock}
      subHeadline={subheadlineBlock}
      headerProps={{
        options: {
          videoType: 'refi',
          voomly: true
        }
      }}
    >
      <SEO
        isRefi
        path={CONST.LINKS.MAIN.REFI.LOWERREPAYMENTS}
        canonical={CONST.LINKS.MAIN.REFI.DEFAULT}
        title="Lower your mortgage payments from your sofa"
        description="More money in your pocket each month. It’s surprisingly simple."
        antiFlicker
      />
      <NumberedListWithImage sideHeading="Refinance like never before." items={numberedList} cash />
      <VideoWithHeading heading="Shrink your monthly payments* without the brain strain." videoSrc={CONST.VIDEOS.VOOMLY.EXPLAINER_REFI} voomly />
      <TextWithChecklistImg heading="Get your Refi-Ready Approval, lickety-split.">
        <Paragraph>
          15 teeny minutes. That’s all it takes to find out how much you could be saving every single month.
          <br />
          <br />
          Wait. What?
          <br />
          <br />
          Yup! Because we collect and verify your actual numbers instantly, we can give you a Refi-Ready Approval with your real rate — just like that.
          <br />
          <br />
          Heck, you can even do it from your sofa.
        </Paragraph>
      </TextWithChecklistImg>
      <FishAndText />
      <LoanGuideConvo />
      <PlantAndCoffee heading="See how much extra you’ll have each month.">
        <Paragraph light fade>
          Play with our refi calculator to see how much you could be putting towards things that make your life better.
        </Paragraph>
        <LegalWrap>
          <Legal>
            *If you’re refinancing your existing loan to get a lower payment, just a heads up that your total finance charges incurred over the life of your new loan could be
            higher than with your existing loan. If you have any questions, please get in touch with your Loan Guide.
          </Legal>
        </LegalWrap>
      </PlantAndCoffee>
    </Template>
  );
};

export default RefinanceLowerRepayments;
